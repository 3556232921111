import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { nFormatter } from '../helpers/digits';
import { Tooltip } from '@mui/material';

export const TrendWay = ({ trend }) => {
  return (
    <div className='flex space-x-1'>
      {/* <span className='pt-0.5'> */}
      {trend ? (
        trend > 0 ? (
          <span className='pt-1'>
            <BiTrendingUp />
          </span>
        ) : (
          <span className='pt-0.5'>
            <BiTrendingDown />
          </span>
        )
      ) : null}
      {/* </span> */}
      <Tooltip title={parseFloat(Math.abs(trend)).toFixed(2)}>
        <span>{trend ? nFormatter(Math.abs(trend)) + '%' : '-'}</span>
      </Tooltip>
    </div>
  );
};
