import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { rankings_data } from '../../data/rankings_data';
import { collectRenkingData } from '../../redux/counterSlice';
import { useDispatch } from 'react-redux';
import useGetRankings from '../../hooks/useGetRankings';
import Dropdown_dynamic from '../../components/dropdown/dropdown_dynamic';
import { ImageFixedAO } from '../../components/Image_fixed_aspect_ratio';
import { numFormatter } from '../../lib/numberFormatter';
import { getCollectionPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ChainIcon } from '../../components/Icon';
import { getTokenName } from '../../helpers/getChainName';
import { TrendWay } from '../../components/trendWay';
import { priceFormatter } from '../../helpers/digits';

const last7DaysRanks = [
  {
    id: '7d',
    text: 'Last 7 Days',
  },
  {
    id: '14d',
    text: 'Last 14 Days',
  },
  {
    id: '30d',
    text: 'Last 30 Days',
  },
];
const RankingTable = ({ limit = 5, home }) => {
  const dispatch = useDispatch();
  const { data } = useGetRankings();
  const [dayRange, setDayRange] = useState(last7DaysRanks[2]);

  useEffect(() => {
    dispatch(collectRenkingData(rankings_data));
  }, [dispatch]);

  return (
    <>
      <div className={home ? '' : 'container'}>
        {!home && (
          <h1 className='py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white'>
            Rankings
          </h1>
        )}
        {/* <!-- Filters --> */}
        <div className='mb-8 flex flex-wrap items-center justify-between'>
          <div className='flex flex-wrap items-center'>
            {/* <!-- Categories --> */}
            {/*<Recently_added_dropdown data={categoryText} dropdownFor="rankingCategories" />*/}

            {/* <!-- Chains --> */}
            {/*<Recently_added_dropdown data={blockchainText} dropdownFor="blockchain" />*/}
          </div>

          {/* last 7 days */}
          <Dropdown_dynamic
            items={last7DaysRanks}
            value={dayRange}
            onSelect={(val) => {
              setDayRange(val);
            }}
          />
          {/* <!--  --> */}
        </div>
        {/* <!-- end filters --> */}

        {/* <!-- Table --> */}
        <div className='scrollbar-custom overflow-x-auto'>
          <div
            role='table'
            className='w-full min-w-[736px] rounded-t-2lg border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
          >
            <div className='flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600' role='row'>
              <div className='w-[28%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  Collection
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  Volume
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  24h %
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  7d %
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  Floor Price
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  Owners
                </span>
              </div>
              <div className='w-[12%] py-3 px-4' role='columnheader'>
                <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                  Items
                </span>
              </div>
            </div>
            {data &&
              data?.[dayRange.id]?.slice(0, limit)?.map((d, i) => {
                if (limit && i > limit) return null;
                if (!d.collection) return null;
                return (
                  <Link
                    href={getCollectionPath(d.collection.chain, d.collection.pathName)}
                    key={'rankings' + dayRange.id + i}
                  >
                    <a className='flex transition-shadow hover:shadow-lg' role='row'>
                      <div
                        className='flex w-[28%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <figure className='relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12'>
                          <ImageFixedAO
                            image={getCdnUrl(
                              d?.collection?.profilePhotoPath,
                              'thumbnail',
                              d?.collection?.updatedAt,
                            )}
                            alt={d?.collection?.name}
                            bottomRounded={true}
                          />
                        </figure>
                        <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                          {d?.collection?.name}
                        </span>
                      </div>
                      <div
                        className='flex w-[12%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <div className='flex flex-col justify-center gap-1.5'>
                          <div className='flex flex-row items-center'>
                            <ChainIcon
                              width={16}
                              name={d.collection.chain}
                              tooltip={getTokenName(d.collection.chain)}
                              sideGap={true}
                            />
                            <span className='text-sm font-medium tracking-tight'>
                              {d?.volume ? numFormatter(d?.volume, 4) : 0}
                            </span>
                          </div>
                          {d?.usdVolume && (
                            <div className='flex flex-row items-center'>
                              <span className='text-xs font-medium tracking-tight'>
                                {d?.usdVolume
                                  ? '$' + priceFormatter(Number(d?.usdVolume?.toFixed(2)))
                                  : 0}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <span className={`flex text-${d?.changeIn24h > 0 ? 'green' : 'red'}`}>
                          <TrendWay trend={d?.changeIn24h} />
                        </span>
                      </div>
                      <div
                        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <span className={`flex text-${d?.changeIn30d > 0 ? 'green' : 'red'}`}>
                          <TrendWay trend={d?.changeIn30d} />
                        </span>
                      </div>
                      <div
                        className='flex w-[12%] items-center whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <div className='flex items-center'>
                          <ChainIcon
                            width={16}
                            name={d.collection.chain}
                            tooltip={getTokenName(d.collection.chain)}
                            sideGap={true}
                          />
                          <span className='text-sm font-medium tracking-tight'>{d?.floor}</span>
                        </div>
                      </div>
                      <div
                        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <span>{d?.owners}</span>
                      </div>
                      <div
                        className='flex w-[12%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                        role='cell'
                      >
                        <span>{d?.items}</span>
                      </div>
                    </a>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RankingTable;
